/* eslint-disable no-unused-vars */

export type Currency = 'IDR'

export enum ErrorActionType {
  BROWSE_EVENT = 'browse_events',
  BACK_TO_EVENT = 'back_to_event',
  RETRY = 'retry',
  TRANSACTION_HISTORY = 'trx_history',
  BACK = 'back',
  TOPUP = 'topup',
  CONTACT_CS = 'contact_cs',
  CLOSE = 'close',
  CANCEL = 'cancel',
  CONTINUE = 'continue',
  CHANGE_CARD = 'change_card',
  REFRESH = 'refresh',
  SELECT_TICKET = 'select_ticket',
  EDIT_BOOKING = 'edit_booking',
  CONTINUE_BOOKING = 'continue_booking',
  CANCEL_BOOKING = 'cancel_booking',
  FINISH_BOOKING = 'finish_booking',
  CREATE_NEW_BOOKING = 'create_new_booking',
  CHECK_CARD_DETAIL = 'check_card_detail',
  RE_BOOK = 're_book',
  KEY_NO_CONNECTION = 'key_no_connection',
  EXIT = 'exit',
}

export type CustomFormType =
  | 'TEXT'
  | 'PARAGRAPH'
  | 'CHECKBOX'
  | 'RADIO_BUTTON'
  | 'DROPDOWN'
  | 'DATE'
  | 'TIME'
  | 'ATTACHMENT'
  | 'DESCRIPTION'
  | 'PHONE'
