var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.22.11"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/webapp/2.22.11";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const enabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true'
const dsn =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || 'local'
const SampleRate =
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE) || 0
const tracesSampleRate =
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0
const debug = process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true' || false
const handleExceptions = [
  'Non-Error promise rejection captured',
  "Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.",
  'UnknownError: Database deleted by request of the user',
  'ERROR(1): User denied Geolocation',
  'Error init firebase: messaging/failed-service-worker-registration - Messaging: We are unable to register the default service worker.',
  'Non-Error exception captured',
  "Can't find variable: Notification",
  "Can't find variable: gmo",
  "Can't find variable: _AutofillCallbackHandler",
  "null is not an object (evaluating 'Fe.get(r.WEB_SETTINGS).configs')",
  "null is not an object (evaluating 'Be.get(r.WEB_SETTINGS).configs')",
  "undefined is not an object (evaluating 'a.K')",
  'window.SafeDKWebAppInterface.interceptXmlHttpRequest is not a function',
  'Cancel rendering route',
]

Sentry.init({
  enabled,
  dsn,
  environment,
  debug,
  beforeSend(event, hint) {
    if (hint?.originalException?.message)
      if (
        handleExceptions.find((error) =>
          hint?.originalException?.message.includes(error),
        )
      ) {
        // Handle exceptions that are in whitelist
        event?.exception?.values?.map(
          (value) => (value.mechanism.handled = true),
        )
      }
    return event
  },

  // Adjust this value in production, or use tracesSampler for greater control
  SampleRate,
  tracesSampleRate,
  integrations: [Sentry.metrics.metricsAggregatorIntegration()],

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
