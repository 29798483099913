import { useFormContext, Controller } from 'react-hook-form'
import { useState } from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import {
  Breakpoint,
  FormControl,
  FormHelperText,
  SxProps,
  TextareaAutosize,
  TextareaAutosizeProps,
  styled,
  useTheme,
} from '@mui/material'

import Typography from 'components/commons/CustomTypography'
import CommonTypography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'

import { StyledInputLabel } from '../styled'

const StyledTextarea = styled(TextareaAutosize)<{
  error?: boolean
}>(({ theme, error = false }) => ({
  ...theme.typography.normalRegular,
  padding: '12px',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.tertiery,
  borderRadius: 6,
  borderWidth: '1px',
  borderColor: error ? theme.palette.error.main : 'transparent',
  marginTop: '16px',

  '&:focus, &:hover': {
    borderWidth: '1px',
    borderColor: error ? theme.palette.error.main : 'transparent',
  },
  '&:focus-visible': {
    outline: 0,
  },
  '::placeholder': {
    color: theme.palette.text.tertiery,
  },
}))

const StyledTextAreaCount = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  ...theme.typography.smallRegular,
  color: theme.palette.text.tertiery,
  background: theme.palette.background.tertiery,
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  padding: '8px 12px',
}))

type ResizeType =
  | 'block'
  | 'both'
  | 'horizontal'
  | 'inline'
  | 'none'
  | 'vertical'

type IProps = {
  name: string
  label?: string
  info?: string
  resize?: ResizeType
  mobileIn?: Breakpoint
  maxCount?: Number
  disableCounter?: boolean
  sx?: SxProps
  description?: string
  disabledErrorBorder?: boolean
}

type Props = IProps & TextareaAutosizeProps

export default function RHFTextArea({
  name,
  label,
  info,
  id,
  resize = 'none',
  required,
  maxCount = 2048,
  disableCounter,
  sx,
  description,
  disabledErrorBorder = false,
  ...other
}: Props) {
  const theme = useTheme()
  const { control } = useFormContext()

  const [textAreaCount, setTextAreaCount] = useState(0)

  const recalculateLength = (e, onChange) => {
    setTextAreaCount(e.target.value.length)
    onChange(e)
  }

  const renderErrorText = (message: string | undefined) => {
    if (message)
      return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <ErrorIcon
            sx={(theme) => ({
              fontSize: 'inherit',
              color: theme.palette.error.main,
            })}
          />
          <FormHelperText
            sx={{
              marginLeft: '4px !important',
              marginTop: '3px !important',
            }}
          >
            {message}
          </FormHelperText>
        </Stack>
      )
  }

  return (
    <FormControl variant="standard" fullWidth>
      <Stack spacing={0.5}>
        {label && (
          <StyledInputLabel
            shrink
            htmlFor={id}
            required={required}
            data-testid="c-common-label-text-area"
            sx={{ position: 'inherit' }}
          >
            {label}
          </StyledInputLabel>
        )}
        {description && (
          <CommonTypography
            data-testid="c-common-description-text-area"
            variant="smallRegular"
            sx={(theme) => ({
              position: 'relative',
              color: theme.palette.text.secondary,
            })}
          >
            {description}
          </CommonTypography>
        )}
        {info && (
          <Typography
            as="span"
            size="xs"
            sx={(theme) => ({
              position: 'absolute',
              right: 0,
              top: 0,
              color: theme.palette.text.secondary,
            })}
          >
            {info}
          </Typography>
        )}
        <Stack>
          <Controller
            name={name}
            control={control as any}
            render={({ field, fieldState: { error } }) => (
              <>
                <StyledTextarea
                  {...field}
                  id={id}
                  style={{
                    resize: resize,
                    overflow: 'auto',
                  }}
                  error={!!error && !disabledErrorBorder}
                  required={required}
                  sx={{
                    marginTop: '0px !important',
                    fontFamily: '"Source Sans 3" !important',
                    ...sx,
                    borderBottom: 'none',
                    borderBottomRightRadius: '0px',
                    borderBottomLeftRadius: '0px',
                  }}
                  onChange={(e) =>
                    recalculateLength(e, field.onChange)
                  }
                  {...other}
                />
                {!disableCounter && (
                  <Stack
                    sx={{
                      ...sx,
                      borderWidth: '1px',
                      borderColor: !!error
                        ? theme.palette.error.main
                        : 'transparent',
                      borderStyle: 'solid',
                      borderTop: 'transparent',
                      borderBottomRightRadius: '6px',
                      borderBottomLeftRadius: '6px',
                    }}
                  >
                    <StyledTextAreaCount data-testid="c-common-text-area-count">
                      {textAreaCount}/{String(maxCount)}
                    </StyledTextAreaCount>
                  </Stack>
                )}
                {renderErrorText(error?.message)}
              </>
            )}
          />
        </Stack>
      </Stack>
    </FormControl>
  )
}
